import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {Device} from "../../../../../models/Device";
import {DeviceSearchOptions, DeviceService} from "../../../../../services/DeviceService";

export function useNetworkDevices(networkId: number | null, querySearchParams: DeviceSearchOptions | null) {
    const [devices, setDevices] = useState<Device[] | null>(null);
    const { isSuccess, data, isLoading, refetch } = useQuery(["devices", {
        network_id: networkId,
        mac: querySearchParams?.mac_address?.trim(),
        name: querySearchParams?.device_name?.trim()
    }], () => {
        if (networkId) {
            return DeviceService.getDevicesForNetwork(networkId, querySearchParams).then((res) => res.data);
        }
    }, {cacheTime: 0, refetchOnWindowFocus: false});

    useEffect(() => {
        if (data) {
            setDevices(data as Device[]);
        }
    }, [isSuccess, data, isLoading])
    return { devices, isSuccess, isLoading, refetch }
}
