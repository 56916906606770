import { useEditUser } from "./hooks/useEditUser";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectLoggedInUser,
  selectUserID,
} from "../../../redux/selectors/authSelector";
import { EditUserForm } from "./components/EditUserForm";
import { EditUserRequest } from "../../../models/EditUserRequest";
import { useEffect } from "react";
import {Alert, Form, Modal, notification} from "antd";
import { selectUserEditModalOpened } from "../../../redux/selectors/userSelector";
import { hideEditUserModal } from "../../../redux/slices/userSlice";
import { setLoggedInUser } from "../../../redux/slices/authSlice";

export function EditUser() {
  const userID = useAppSelector(selectUserID);
  const user = useAppSelector(selectLoggedInUser);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const userEditModalVisible = useAppSelector(selectUserEditModalOpened);

  const { submitEditUser, isLoading, isSuccess, data, isError, error } = useEditUser();

  const handleEditUser = (data: any) => {
    const editUserInfo = data as EditUserRequest;
    if (userID) {
      editUserInfo.user_id = userID;
    }
    submitEditUser(editUserInfo);
  };

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(hideEditUserModal());
      dispatch(setLoggedInUser(data));
      notification.success({
        message: "You've successfully edited your profile!",
      });
    }
  }, [isSuccess, dispatch, data]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(hideEditUserModal());
  };

  return (
    <Modal
      title="Edit User"
      open={userEditModalVisible}
      okButtonProps={{
        loading: isLoading,
        style: { background: '#05a955', borderColor: '#05a955' }
      }}
      bodyStyle={{
        height: 600,
        overflowY: "auto",
      }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {isError && (error as any) && (
          <Alert
              style={{marginBottom: 10}}
              message={(error as any)?.response?.data?.detail || "An error occurred"}
              type="error"
          />
      )}
      <EditUserForm
        form={form}
        handleSubmit={handleEditUser}
        isLoading={isLoading}
        loggedInUser={user}
      />
    </Modal>
  );
}
