import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Table,
  Typography
} from "antd";
import React, {useEffect, useState} from "react";
import {Network} from "../../../../../../models/Network";
import {useUserIdByUsernameOrEmail} from "../../../../hooks/useUserByUsernameOrEmail";
import {useNetworkOwners} from "./hooks/useNetworkOwners";
import {CloseOutlined} from "@ant-design/icons";
import {UserBasicInfo} from "../../../../../../models/UserBasicInfo";
import {useEditNetworkPermission} from "./hooks/useEditNetworkPermission";
import {NetworkPermission, UserNetworkPermission} from "../../../../../../models/UserNetworkPermission";
import {useAppSelector} from "../../../../../../redux/hooks";
import {selectLoggedInUser} from "../../../../../../redux/selectors/authSelector";

type ManagePermissionsProps = {
  isModalVisible: boolean;
  setIsModalVisible: any;
  network: Network;
  refetchNetworks: () => void;
}

export function ManagePermissions({network, isModalVisible, setIsModalVisible, refetchNetworks}: ManagePermissionsProps) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userId, isError, error, isSuccess, refetch } = useUserIdByUsernameOrEmail(usernameOrEmail);
  const { networkOwners, networkOwnersError, networkOwnersIsSuccess } = useNetworkOwners(network.id);
  const { submitEditNetworkPermission, editNetworkPermissionIsSuccess, editNetworkPermissionError, updatedNetworkPermission } = useEditNetworkPermission(network.id);
  const loggedInUser = useAppSelector(selectLoggedInUser);

  const [form] = Form.useForm();

  useEffect(() => {
    if (networkOwners.length && networkOwnersIsSuccess) {
      setValidationError('');
    }
  }, [networkOwners, networkOwnersIsSuccess])

  useEffect(() => {
    if ((networkOwnersError as any)?.response?.status) {
      setValidationError((networkOwnersError as any)?.response?.data?.detail);
    }
  }, [networkOwnersError])

  useEffect(() => {
    if ((error as any)?.response?.status === 404) {
      setValidationError((error as any)?.response?.data?.detail);
      setIsLoading(false);
    } else if ((error as any)?.response?.status) {
      setValidationError('Error happened');
      setIsLoading(false);
    }
  }, [error])

  useEffect(() => {
     if ((editNetworkPermissionError as any)?.response?.status) {
       setValidationError((editNetworkPermissionError as any)?.response?.data?.detail);
       setIsLoading(false);
    }
  }, [editNetworkPermissionError])

  useEffect(() => {
    if (editNetworkPermissionIsSuccess) {
      setIsLoading(false);
      setValidationError('');
      setUsernameOrEmail('');
      form.setFieldValue('usernameOrEmail', '');
      notification.success({
        message: "You've successfully updated network permissions"
      });
      refetchNetworks();
      if (updatedNetworkPermission?.username === loggedInUser?.username) {
        setIsModalVisible(false);
      }
    }
  }, [editNetworkPermissionIsSuccess])

  useEffect(() => {
    if (userId) {
      const userNetworkPermission: UserNetworkPermission = {
        user_id: userId,
        permission: NetworkPermission.GRANT,
        networkId: network.id
      }
      submitEditNetworkPermission(userNetworkPermission);
    }
  }, [userId])

  const handleSubmit = async (data: any) => {
    if (data.usernameOrEmail !== usernameOrEmail) {
      setIsLoading(true);
      setUsernameOrEmail(data.usernameOrEmail);
      form.setFieldValue('usernameOrEmail', data.usernameOrEmail);
      await refetch();
    }
  }

  const handleCancel = () => {
    setUsernameOrEmail('');
    setValidationError('');
    form.setFieldValue('usernameOrEmail', '');
    setIsLoading(false);
    setIsModalVisible(false);
  }

  const removePermission = (record: UserBasicInfo) => {
    setIsLoading(true);
    const userNetworkPermission: UserNetworkPermission = {
      user_id: record.id,
      permission: NetworkPermission.DENY,
      networkId: network.id
    }
    submitEditNetworkPermission(userNetworkPermission);
  }

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      inputType: 'text',
      width: '35%',
      align: 'left',
      render: (_: any, record: UserBasicInfo) => {
        return (
            <span style={record?.id === loggedInUser?.id ? {fontWeight: 'bold'} : {fontWeight: 'normal'}}>
              {record?.username}
            </span>
        )
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      inputType: 'text',
      width: '30%',
      align: 'left',
    },
    {
      title: "Actions",
      dataIndex: "edit",
      width: "15%",
      align: 'center',
      render: (_: any, record: UserBasicInfo) => {
        if (record?.type === 'Owner') {
          return;
        }
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Popover content={'Remove permission'}>
                <Typography.Link >
                  <Popconfirm
                      title="Are you sure you want to remove access permission for this user ?"
                      onConfirm={() => removePermission(record)}
                      okText="Remove"
                      cancelText="Cancel"
                  >
                    <CloseOutlined style={{ fontSize: "20px", color: 'red' }} />
                  </Popconfirm>
                </Typography.Link>
              </Popover>
            </div>
        );
      },
    }
  ]

  return (
      <Modal
          title={"Manage Network Permissions - " + network.SSID}
          open={isModalVisible}
          footer={null}
          bodyStyle={{
            height: "auto",
            overflowY: "auto"
          }}
          onCancel={handleCancel}
      >
        {validationError &&
            <Alert
                style={{marginBottom: 10}}
                message={validationError}
                type="error"
            />
        }
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
          <Form.Item
              name={'usernameOrEmail'}
              label={'Username or Email'}
              rules={[{ required: true, message: 'Please input username or email' }]}
          >
            <Input />
          </Form.Item>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Form.Item>
              <Button loading={isLoading} htmlType="submit" style={{backgroundColor: '#05a955', borderColor: '#05a955', color: 'white'}}>Grant Access</Button>
            </Form.Item>
          </div>
        </Form>
        <div>
          <Table
              bordered
              className={'devices-table'}
              dataSource={networkOwners}
              columns={columns as any}
              rowClassName="editable-row"
              rowKey="id"
              pagination={false}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'end', marginTop: 20, height: 30}}>
          <Form.Item>
            <Button onClick={handleCancel} >Cancel</Button>
          </Form.Item>
        </div>
      </Modal>
  )
}
