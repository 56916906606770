import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import { AuthService } from "../../../../services/AuthService";
import { ChangePasswordRequest } from "../../../../models/ChangePasswordRequest";

export function useChangePassword() {
    const mutation = useMutation((changePassword: ChangePasswordRequest) =>
        AuthService.changePassword(changePassword)
    );
    const [res, setRes] = useState(null);
    useEffect(() => {
        if (mutation.isSuccess && mutation.data) {
            setRes(mutation.data.data);
        }
    }, [mutation.isSuccess, mutation.data]);

    return {
        submitChangePassword: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        data: res,
        error: mutation.error,
        isError: mutation.isError,
    };
}
