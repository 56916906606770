import {useMutation, useQueryClient} from "react-query";
import {Device} from "../../../../../models/Device";
import {DeviceService} from "../../../../../services/DeviceService";

export function useEditDevice() {
    const queryClient = useQueryClient();
    const mutation = useMutation((device: Device) =>
        DeviceService.updateDevice(device).then(res => res.data), {
        onSuccess: (data, variables) => {
            const previousDevices: Device[] | undefined = queryClient.getQueryData(
                ["devices", { network_id: data.network_id }]
            );
            if (!previousDevices) return;
            let updatedDevices: Device[] = previousDevices.slice();
            const deviceToUpdate = updatedDevices.find(d => d.id === data.id);
            if (!deviceToUpdate) return;
            const idxToUpdate = updatedDevices.indexOf(deviceToUpdate);

            updatedDevices = [
                ...updatedDevices.slice(0, idxToUpdate),
                variables,
                ...updatedDevices.slice(idxToUpdate + 1),
            ];
            queryClient.setQueryData(["devices", { network_id: data.network_id }], updatedDevices);
        }}
    )

    return {
        submitEditDevice: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
