import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { AuthService } from "../../../../services/AuthService";

interface LoginData {
    username: string;
    password: string;
}
export function useLogin() {
    const mutation = useMutation((loginData: LoginData) => 
        AuthService.secureLogin(loginData.username, loginData.password)
    );
    const [res, setRes] = useState(null);
    useEffect(() => {
        if (mutation.isSuccess && mutation.data) {
            setRes(mutation.data.data);
        }
    }, [mutation.isSuccess, mutation.data]) 

    return {
        submitLogin: mutation.mutate, 
        isLoading: mutation.isLoading, 
        isSuccess: mutation.isSuccess,
        data: res,
        error: mutation.error,
        isError: mutation.isError, 
    }
}