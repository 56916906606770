import {Network} from "../network/Network";

export function Home() {

    return(
        <>
            <Network />
        </>
    )
}
