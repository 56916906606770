import { useMutation, useQueryClient } from "react-query";
import { NetworkService } from "../../../../../../../services/NetworkService";
import {NetworkPermission, UserNetworkPermission} from "../../../../../../../models/UserNetworkPermission";
import { UserBasicInfo } from "../../../../../../../models/UserBasicInfo";

export function useEditNetworkPermission(networkId: number) {
  const queryClient = useQueryClient();
  const mutation = useMutation((networkPermission: UserNetworkPermission) =>
      NetworkService.updateNetworkPermission(networkPermission).then(res => res.data), {
    onSuccess: (data, variables) => {
      let previousNetworkOwners: UserBasicInfo[] | undefined = queryClient.getQueryData(
          ["networkOwners", networkId]
      );
      if (!previousNetworkOwners) return;

      if (variables.permission === NetworkPermission.DENY) {
        previousNetworkOwners = previousNetworkOwners.filter(owner => owner.id !== variables.user_id)
      } else {
        const networkOwner: UserBasicInfo = {
          id: variables.user_id,
          type: 'Has Permission',
          username: data.username
        }
        previousNetworkOwners = [...previousNetworkOwners, networkOwner];
      }
      queryClient.setQueryData(["networkOwners", networkId], previousNetworkOwners);
    }
  });

  return {
    submitEditNetworkPermission: mutation.mutate,
    isLoading: mutation.isLoading,
    editNetworkPermissionIsSuccess: mutation.isSuccess,
    editNetworkPermissionError: mutation.error,
    isError: mutation.isError,
    updatedNetworkPermission: mutation.data
  }
}
