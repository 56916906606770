export interface UserNetworkPermission {
  user_id: number;
  permission: NetworkPermission;
  networkId: number;
}

export enum NetworkPermission {
  GRANT = 'grant',
  DENY = 'deny'
}
