import {useEditDevice} from "../../hooks/useEditDevice";
import {Device} from "../../../../../../models/Device";
import {Button, Form, Input, Modal, notification} from "antd";
import {useEffect} from "react";

type EditDeviceNameProps = {
    device: Device;
    form: any;
    isModalVisible: boolean;
    setIsModalVisible: (isVisible: boolean) => void;
}

export function EditDeviceName({
    device,
    form,
    isModalVisible,
    setIsModalVisible
}: EditDeviceNameProps) {

    const { submitEditDevice, isSuccess } = useEditDevice();

    useEffect(() => {
        if (isSuccess) {
            setIsModalVisible(false);
            notification.success({
                message: "You've successfully updated device"
            });
        }
    }, [isSuccess])



    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const handleOk = () => {
        form.submit();
    }

    const handleSubmit = async (data: Device) => {
        device = {
            ...device,
            name: data.name
        }
        handleEditDevice(device);
        setIsModalVisible(false);
    };

    const handleEditDevice = (data: any) => {
        submitEditDevice(data);
        setIsModalVisible(false);
    }

    return (
        <Modal
            title="Edit Device Name"
            open={isModalVisible}
            bodyStyle={{
                height: "auto",
                overflowY: "auto"
            }}
            okButtonProps={{
                style: { background: '#05a955', borderColor: '#05a955' }
            }}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    initialValue={device.name}
                    name={'name'}
                    label={'Device Name'}
                    key={device.id}
                    rules={[
                        {
                            max: 32,
                            message: 'Maximum number of characters is 32'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item style={{display: "none"}}>
                    <Button htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
