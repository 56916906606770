import { Avatar } from "antd";
import { AYRMESH_BUTTON_COLOR } from "../../constants/ColorConstants";

export function AyrmeshAvatar(props: any) {
    return (
      <Avatar size="large" style={{
        backgroundColor: AYRMESH_BUTTON_COLOR,
        fontWeight: "bold",
        marginRight: 5
      }} {...props}>{props.children}</Avatar>
    )
}