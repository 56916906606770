import logo from '../../../assets/logo.png';
import './Register.css';
import {RegisterForm} from "./components/RegisterForm";
import {useRegister} from "./hooks/useRegister";
import {RegisterRequest} from "../../../models/RegisterRequest";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {LOGIN} from "../../../constants/RoutePathConstants";
import {Alert} from "antd";

export function Register() {
  const { submitRegister, isLoading, isSuccess, data, isError, error } = useRegister();
  const navigate = useNavigate();
  const handleRegister = (data: any) => {
    const registerInfo = data as RegisterRequest;
    registerInfo.subscribed_to_newsletter = !!registerInfo.subscribed_to_newsletter;
    submitRegister(registerInfo);
  }

  let validationError;
  const potentialError = error as any;
  if (potentialError?.response?.status === 422) {
    validationError = potentialError?.response?.data;
  }

  useEffect(() => {
    if (isSuccess && data) {
      navigate(LOGIN, { state: { justRegistered: true }});
    }
  }, [isSuccess, data]);
  return (
    <div className="register-container">
      <img width="300px" src={logo} alt="Ayrstone logo" />
      {isError && !validationError && (error as any) && (
        <Alert
          style={{marginBottom: 10, width: 300}}
          message={(error as any)?.response?.data?.detail || "An error occurred"}
          type="error"
        />
      )}
      {isError && validationError && (validationError.detail.map((e: any, idx: number) =>
        <Alert
          key={idx}
          style={{marginBottom: 10, width: 300}}
          type="error"
          message={
            <>
              <span style={{fontWeight: "bold"}}>
                {e.loc[1]}:
              </span> {" "} {e.msg}
            </>
          }
        />
      ))}
      <RegisterForm handleSubmit={handleRegister} isLoading={isLoading} />
    </div>
  )
}
