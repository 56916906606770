import { Button } from "antd";
import { AYRMESH_BUTTON_COLOR } from "../../constants/ColorConstants";

export function AyrmeshButton(props: any) {
    let style = props.style;
    if (!style) {
        style = {}
    }
    if (!style["background"]) {
        style["background"] = AYRMESH_BUTTON_COLOR;
        style["borderColor"] = AYRMESH_BUTTON_COLOR;
    }
    return (
        <Button {...props} style={style}>{props.children}</Button>
    )
}
