import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {NetworkService} from "../../../../../services/NetworkService";
import {Network} from "../../../../../models/Network";
import {DeviceSearchOptions} from "../../../../../services/DeviceService";

export function useNetwork(userId: number | null, querySearchParams: DeviceSearchOptions) {
    const [networks, setNetworks] = useState<Network[] | null>(null);
    const { isSuccess, data, isLoading, refetch } = useQuery(["networks"], () => {
        if (userId) {
            return NetworkService.getAllNetworks(userId, querySearchParams).then((res) => res.data);
        }
    }, {cacheTime: 0, refetchOnWindowFocus: false});

    useEffect(() => {
        if (data) {
            setNetworks(data as Network[]);
        }
    }, [isSuccess, data, isLoading])
    return { networks, isSuccess, isLoading, refetch }
}
