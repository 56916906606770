import {useMutation, useQueryClient} from "react-query";
import {Device} from "../../../../../models/Device";
import {DeviceService} from "../../../../../services/DeviceService";

export function useSoftDeleteDevice(record?: Device) {
    const queryClient = useQueryClient();
    const mutation = useMutation((device: Device) =>
        DeviceService.softDeleteDevice(device).then(res => res.data), {
        onSuccess: () => {
            if (record) {
                const previousDevices: Device[] | undefined = queryClient.getQueryData(
                    ["devices", { network_id: record.network_id }]
                );
                if (!previousDevices) return;
                let allDevices: Device[] = previousDevices.slice();

                allDevices = allDevices.filter(device => device.id !== record.id);
                queryClient.setQueryData(["devices", { network_id: record.network_id }], allDevices);
            }
        }}
    )

    return {
        softDeleteDevice: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
