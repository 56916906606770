import {API_BASE_URL} from "../configs/AppConfig";
import authService from "../configs/AxiosInterceptor";
import {Network} from "../models/Network";
import {DeviceSearchOptions} from "./DeviceService";
import {UserNetworkPermission} from "../models/UserNetworkPermission";

export interface NetworkSearchOptions {
    username: string | null;
    email: string | null;
    phone_number: string | null;
    first_name: string | null;
    last_name: string | null;
    business_name: string | null;
    device_ip: string | null;
    network_ip: string | null;
    ssid: string | null;
    mac_address: string | null;
}

export class NetworkService {
    private static RESOURCE_BASE = `${API_BASE_URL}/api/v1/networks`;

    static getUserNetworksByUserId(user_id: number) {
        return authService.get(`${this.RESOURCE_BASE}/user/${user_id}`)
    }

    static getAllNetworks(user_id: number, querySearchParams: DeviceSearchOptions) {
        let queryParams: any = {};
        if (querySearchParams) {
            queryParams = querySearchParams;
        }
        return authService.get(`${this.RESOURCE_BASE}/user/${user_id}/full-details`, {params: queryParams})
    }

    static updateNetwork(networkSettings: Network) {
        return authService.put(`${this.RESOURCE_BASE}/${networkSettings.id}`, networkSettings);
    }

    static searchNetworks(searchOptions: NetworkSearchOptions | null, paginationParams: {page: number, limit: number} | null) {
        let queryParams: any = {};
        if (searchOptions) {
            queryParams = searchOptions;
        }
        if (paginationParams) {
            queryParams = {...queryParams, ...paginationParams}
        }
        return authService.get(this.RESOURCE_BASE, {params: queryParams});
    }

    static getNetworkOwnersByNetworkId(networkId: number) {
        return authService.get(`${this.RESOURCE_BASE}/${networkId}/users`);
    }

    static updateNetworkPermission(networkPermission: UserNetworkPermission) {
        return authService.post(`${this.RESOURCE_BASE}/${networkPermission.networkId}/permission`, networkPermission);
    }
}
