import {Button} from "antd";
import './ForgotPassword.css';
import {useNavigate, useParams} from "react-router-dom";
import {LOGIN} from "../../../constants/RoutePathConstants";
import logo from "../../../assets/logo.png";
import {useResetPassword} from "./hooks/useResetPassword";
import {ResetPasswordRequest} from "../../../models/ResetPasswordRequest";
import {useEffect} from "react";


export function ForgotPasswordToken() {
    const { submit, isSuccess, isError, error } = useResetPassword();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.token) {
            const resetPasswordRequest: ResetPasswordRequest = {
                forgot_password_hash: params.token
            }
            submit(resetPasswordRequest);
        }
    }, [])

    const navigateToLogin = () => {
        navigate(LOGIN);
    }

    return (
        <div className="container">
            <img width="300px" src={logo} alt="Ayrstone logo" />
            <h3 style={{marginTop: 10, marginBottom: 10}}>
              { isSuccess &&
                  'We have successfully reset your password.'
              }
              { isError &&
                  'Error happened during resetting your password.'
              }
            </h3>
            <h3 style={{marginTop: 10, marginBottom: 10}}>
              { isSuccess &&
                  'Check your email for the new one.'
              }
              { isError &&
                  'Please contact our support team on support@ayrstone.com.'
              }
            </h3>
            <div style={{textAlign: 'center'}}>
                <Button onClick={() => navigateToLogin()} style={{paddingLeft: 0}} type="link">Sign in</Button>
            </div>
        </div>
    )
}
