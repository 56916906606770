import { API_BASE_URL } from "../configs/AppConfig";
import authService from "../configs/AxiosInterceptor";
import { EditUserRequest } from "../models/EditUserRequest";

export interface UserSearchOptions {
    username: string | null;
}

export class UserService {
    private static RESOURCE_BASE = `${API_BASE_URL}/api/v1/users`;

    static getUserByID(id: number) {
        return authService.get(`${this.RESOURCE_BASE}/${id}`);
    }

    static getUserIdByUsernameOrEmail(usernameOrEmail: string) {
        return authService.get(`${this.RESOURCE_BASE}/username-or-email/${usernameOrEmail}`);
    }

    static editUser(userDetails: EditUserRequest) {
        return authService.put(
            `${this.RESOURCE_BASE}/${userDetails.user_id}`,
            userDetails
        );
    }
}
