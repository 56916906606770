import logo from '../../../assets/logo.png';
import {Alert, Button, Form, Input} from "antd";
import './ForgotUsername.css';
import {useNavigate} from "react-router-dom";
import {LOGIN} from "../../../constants/RoutePathConstants";
import {AyrmeshButton} from "../../../shared-components/ayrmesh-button/AyrmeshButton";
import {useForgotUsername} from "./hooks/useForgotUsername";


export function ForgotUsername() {
  const { submit, isLoading, isSuccess, isError, error } = useForgotUsername();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const navigateToLogin = () => {
    navigate(LOGIN);
  }


  return (
      <div className="container">
        <img width="300px" src={logo} alt="Ayrstone logo" />
        <h3 style={{marginTop: 10, marginBottom: 10}}>Recover your username</h3>
        {!isSuccess ?
            <>
              {isError && (error as any) && (
                  <Alert
                      style={{marginBottom: 10}}
                      message={(error as any)?.response?.data?.detail || "An error occurred"}
                      type="error"
                  />
              )}
              <p style={{marginTop: 10, marginBottom: 20}}>Enter your email and we’ll send you a username on your email.</p>
              <Form
                  form={form}
                  layout="vertical"
                  name="basic"
                  onFinish={submit}
                  autoComplete="off"
              >
                <Form.Item
                    style={{marginBottom: 5}}
                    label="Email"
                    name="email"
                    rules={[{required: true, message: 'Please input your email!'}]}
                >
                  <Input />
                </Form.Item>
                <Form.Item style={{marginBottom: 10, marginTop: 20}}>
                  <AyrmeshButton loading={isLoading} block type="primary" htmlType="submit">
                    Send request
                  </AyrmeshButton>
                </Form.Item>
              </Form>
              <div style={{textAlign: 'center'}}>
                <Button onClick={() => navigateToLogin()} style={{paddingLeft: 0}} type="link">Sign in</Button>
              </div>
            </> :
            <>
              <p style={{marginTop: 10, marginBottom: 20}}>An email has been sent. Please check your username in the email.</p>
              <div style={{textAlign: 'center'}}>
                <Button onClick={() => navigateToLogin()} style={{paddingLeft: 0}} type="link">Sign in</Button>
              </div>
            </>
        }
      </div>
  )
}
