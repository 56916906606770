import {RegisterRequest} from "../../../../models/RegisterRequest";
import {AuthService} from "../../../../services/AuthService";
import {useMutation} from "react-query";
import {useEffect, useState} from "react";

export function useRegister() {
  const mutation = useMutation((registerData: RegisterRequest) =>
    AuthService.register(registerData)
  );
  const [res, setRes] = useState(null);

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) {
      setRes(mutation.data.data);
    }
  }, [mutation.isSuccess, mutation.data])

  return {
    submitRegister: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    data: res,
    error: mutation.error,
    isError: mutation.isError,
  }
}