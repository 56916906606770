import {Button, Spin} from "antd";
import { Header } from "antd/lib/layout/layout";
import logo from '../../../../assets/logo.png';
import {useAppSelector} from "../../../../redux/hooks";
import {selectLoggedInUser} from "../../../../redux/selectors/authSelector";
import {useNavigate} from "react-router-dom";
import {APP} from "../../../../constants/RoutePathConstants";
import {ProfileDropdown} from "./components/profile-dropdown/ProfileDropdown";
import {AYRMESH_BUTTON_COLOR} from "../../../../constants/ColorConstants";
import {User} from "../../../../models/User";


export function AppHeader() {
    const loggedInUser: User | null = useAppSelector(selectLoggedInUser);
    const navigate = useNavigate();

  const navigateToAdminApp = () => {
    if (window.location.href.includes('localhost')) {
      const port = window.location.port;
      window.location.href = `http://localhost:${port}/admin/app`;
    } else {
      window.location.href = `https://${window.location.hostname}/admin/app`;
    }
  }

    return (
        <Header style={{
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
            background: '#fff',
            display: "flex",
            alignItems: "center"
        }}>
            <div className="logo">
                <a onClick={() => navigate(APP)}>
                    <img alt="Ayrstone Logo" style={{height: '50px'}} src={logo} />
                </a>
              {loggedInUser?.roles === 'ADMIN' &&
                  <Button style={{background: AYRMESH_BUTTON_COLOR, borderColor: AYRMESH_BUTTON_COLOR, marginLeft: 20}}
                          onClick={() => navigateToAdminApp()} type="primary">
                    Switch to Admin
                  </Button>
              }
            </div>
            <div style={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end",
                alignItems: "center"
            }}>
                <Spin spinning={!loggedInUser} />
                {loggedInUser && (
                    <ProfileDropdown firstName={loggedInUser.first_name} lastName={loggedInUser.last_name} username={loggedInUser.username} />
                )}
            </div>
        </Header>
    )
}
