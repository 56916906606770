import {AuthService} from "../../../../services/AuthService";
import {useMutation} from "react-query";
import {ForgotUsernameRequest} from "../../../../models/ForgotUsernameRequest";

export function useForgotUsername() {
  const mutation = useMutation((forgotUsernameRequest: ForgotUsernameRequest) =>
      AuthService.forgotUsername(forgotUsernameRequest.email)
  );

  return {
    submit: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    error: mutation.error,
    isError: mutation.isError,
  }
}
