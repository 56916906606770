import {useQuery} from "react-query";
import {AuthService} from "../../../../services/AuthService";
import {UsernameAvailability} from "../../../../models/UsernameAvailability";
import {useEffect, useState} from "react";

export function useUsernameAvailability(username: string | null) {
  const {
    isLoading,
    isSuccess,
    data,
    error,
  } = useQuery(["username_availability", username], () =>
  {
    if (!username) return Promise.resolve();
    return AuthService.checkUsernameAvailability(username).then(res => res.data);
  },
    {cacheTime: 0, refetchOnWindowFocus: false, refetchInterval: 0},
  )

  const [usernameAvailable, setUsernameAvailable] = useState<boolean | null>(null)

  useEffect(() => {
    if (isSuccess && data) {
      const usernameAvailability = data as UsernameAvailability;
      setUsernameAvailable(usernameAvailability.available);
    }
  }, [data, isSuccess])

  return {
    isLoading,
    usernameAvailable,
  }
}