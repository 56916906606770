import React from "react";
import {Popover, Tag} from "antd";
import {DEFAULT_ENCRYPTION_PASSKEY} from "../../../../../constants/APIConstants";

export function useNetworkColumns() {

    const popoverContent = (
        <p style={{ margin: 0 }}>It is dangerous to use the default passkey - please change it to something more secure</p>
    );

    return [
        {
            title: "SSID (Network name)",
            dataIndex: "SSID",
            editable: true,
            inputType: 'text',
            width: '25%',
            align: 'center',
        },
        {
            title: "Encryption passkey",
            dataIndex: "wpa_key",
            editable: true,
            inputType: 'text',
            width: '25%',
            align: 'center',
            render: (encryptionPasskey: string) => {
                if (encryptionPasskey === DEFAULT_ENCRYPTION_PASSKEY) {
                    return (
                        <Popover content={popoverContent}>
                            <Tag color="error">{encryptionPasskey}</Tag>
                        </Popover>
                    )
                }
                return <Tag color="success">{encryptionPasskey}</Tag>
            }
        },
        {
            title: "WiFi Channel",
            dataIndex: "channel",
            editable: true,
            inputType: 'number',
            width: '25%',
            align: 'center',
        },
    ]
}
