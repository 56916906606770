import {Col, Row} from "antd";

type DeviceDetailRowProps = {
    labelFirst: string;
    labelSecond?: string;
    valueFirst: string | number;
    valueSecond?: string | number | boolean;
}

export function DeviceDetailRow({
    labelFirst,
    labelSecond,
    valueFirst,
    valueSecond
}: DeviceDetailRowProps) {

    return (
        <>
            <Row>
                <Col span={4}>
                    <p>{labelFirst}:</p>
                </Col>
                <Col span={8}>
                    <p className="bold-txt">{valueFirst}</p>
                </Col>
                <Col span={4}>
                    {labelSecond &&
                        <p>{labelSecond}:</p>
                    }
                </Col>
                <Col span={8}>
                    {valueSecond &&
                        <p className="bold-txt">{valueSecond}</p>
                    }
                </Col>
            </Row>
            <hr className="line-margin"/>
        </>
    )
}
