import {Network} from "../../../../models/Network";
import React, {useEffect, useState} from "react";
import {Button, Col, Collapse, Form, Grid, Input, Popconfirm, Popover, Row, Table, Typography} from "antd";
import {EditOutlined, PlusCircleOutlined, SearchOutlined, DeleteOutlined} from "@ant-design/icons";
import {useDeviceColumns} from "./hooks/useDeviceColumns";
import {AddDevice} from "./components/add-device/AddDevice";
import {useNetworkDevices} from "./hooks/useNetworkDevices";
import {Device} from "../../../../models/Device";
import './components/editable-table/EditableTable.css';
import {DeviceDetails} from "./components/device-details/DeviceDetails";
import {EditDeviceName} from "./components/edit-device-name/EditDeviceName";
import './DeviceTable.css';
import {DeviceSearchOptions} from "../../../../services/DeviceService";
import {TypeOfDevice} from "../../../../enums/TypeOfDevice";
import logo from "../../../../assets/logo_no_text.png";
import {useSoftDeleteDevice} from "./hooks/useSoftDeleteDevice";
import {useDeviceMappingTypes} from "./hooks/useDeviceMappingTypes";

const { Panel } = Collapse;

type DeviceTableProps = {
    network: Network;
    querySearchParams: DeviceSearchOptions;
}

type DeviceByType = {
    deviceType: string;
    devices: Device[];
}

export function DeviceTable({ network, querySearchParams }: DeviceTableProps) {
    const [addDeviceModalVisible, setAddDeviceModalVisible] = useState(false);
    const {devices, refetch} = useNetworkDevices(network.id, querySearchParams);
    const {deviceMappings} = useDeviceMappingTypes();

    const [isDeviceDetailsModalVisible, setIsDeviceDetailsModalVisible] = useState(false);
    const [isEditDeviceModalVisible, setIsEditDeviceModalVisible] = useState(false);
    const [deviceForDetailModal, setDeviceForDetailModal] = useState<Device>();
    const [devicesByTypes, setDevicesByTypes] = useState<DeviceByType[]>([]);
    const [deviceToDelete, setDeviceToDelete] = useState<Device>();
    const [activeKey, setActiveKey] = useState(['0']);

    const {softDeleteDevice} = useSoftDeleteDevice(deviceToDelete);

    const [form] = Form.useForm();

    const edit = (record: Device) => {
        form.setFieldsValue({ ...record });
        setIsEditDeviceModalVisible(true);
        setDeviceForDetailModal(record);
    };

    const onDeleteDeviceClick = (record: Device) => {
        setDeviceToDelete(record);
    }

    const confirmDeleteDevice = () => {
        if (deviceToDelete) {
            softDeleteDevice(deviceToDelete);
        }
    };

    const columns = [
        ...useDeviceColumns(),
        {
            dataIndex: 'edit',
            title: 'Actions',
            width: '8%',
            align: "center",
            render: (_: any, record: Device) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <Popover content={'Edit'}>
                            <Typography.Link onClick={() => edit(record)}>
                                <EditOutlined twoToneColor="#7a7a7a" style={{ fontSize: '20px', color: '#7a7a7a' }} />
                            </Typography.Link>
                        </Popover>
                        <Popover content={'Delete'}>
                            <Typography.Link onClick={() => onDeleteDeviceClick(record)}>
                                <Popconfirm
                                    title="Do not delete unless you no longer possess the device!"
                                    onConfirm={confirmDeleteDevice}
                                    okText="Delete"
                                    cancelText="Cancel"
                                >
                                    <DeleteOutlined twoToneColor="#FF0000" style={{ fontSize: '20px', color: '#FF0000' }} />
                                </Popconfirm>
                            </Typography.Link>
                        </Popover>
                    </div>
                );
            },
        },
    ]


    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch(); // Fetch data every minute
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        refetch();
    }, [querySearchParams])

    useEffect(() => {
        if (devices?.length && deviceMappings?.length) {
            const unknownDeviceType = 'Unknown'; //deviceMappings.find(map => map.device_key === 'null')?.device_value ?? 'Unknown';
            const types = [...new Set(devices?.map((device: Device) => device.model ? (deviceMappings.find(map => map.device_key === device.model)?.device_value ?? unknownDeviceType) //(TypeOfDevice as any)[device.model]
                : device.type ? (deviceMappings.find(map => map.device_key === device.type)?.device_value ?? unknownDeviceType) : unknownDeviceType))];
            const typesByPriority = ['Hub2', 'Hub2n', 'Hub2T', 'Hub2x2', 'Hub2x2C', 'Receiver', 'ReceiverC', 'IndoorHub', 'IndoorAP', 'Bridge radio', 'Hubduo'];
            types.sort((a, b) => typesByPriority.indexOf(a) - typesByPriority.indexOf(b));
            const devicesByTypeList: DeviceByType[] = [];
            types.forEach(deviceType => {
                const devicesByType: DeviceByType = {
                    deviceType,
                    devices: devices ? devices.filter(device => (device.model ? (deviceMappings.find(map => map.device_key === device.model)?.device_value ?? unknownDeviceType) :
                        device.type ? (deviceMappings.find(map => map.device_key === device.type)?.device_value ?? unknownDeviceType) : unknownDeviceType) === deviceType) : []
                }
                devicesByTypeList.push(devicesByType);
            })
            setDevicesByTypes(devicesByTypeList);
            setActiveKey(['0']);
        }
    }, [devices, deviceMappings])

    const onChange = (key: string | string[]) => {
        setActiveKey(key.length ? key[1] ? [key[1]] : [key[0]] : []);
    };

    return (
        <>
            <div className="table-header">
                <h2 style={{marginRight: 15}}>Devices</h2>
                <div style={{display: 'flex'}}>
                    <Button
                        style={{marginLeft: 20}}
                        icon={<PlusCircleOutlined />}
                        onClick={() => setAddDeviceModalVisible(true)}
                        type="default">
                        Add Device
                    </Button>
                </div>
            </div>
            <Collapse onChange={onChange} activeKey={activeKey}>
                {devicesByTypes.map((devicesByType, key) => (
                    <Panel collapsible={"header"} showArrow={false} key={key} header={
                        <div style={{display: 'flex'}}>
                            <img style={{
                                width: 25
                            }} src={logo} alt="Logo no text"/>
                            <h3 style={{
                                display: "inline-block",
                                marginBottom: 0,
                                marginLeft: 10,
                                width: '100vh'
                            }}>{devicesByType.deviceType}</h3>
                        </div>
                    }>
                        <Table
                            bordered
                            className={'devices-table'}
                            dataSource={devicesByType.devices ? [...devicesByType.devices] : []}
                            columns={columns as any}
                            rowClassName="device-row"
                            scroll={{ x: 500 }}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (e) => {
                                        const tagName = (e.target as Element).tagName;
                                        if (tagName !== 'svg' && tagName !== 'path' && tagName !== 'SPAN' && tagName !== 'BUTTON' && tagName !== 'DIV') {
                                            setDeviceForDetailModal(record);
                                            setIsDeviceDetailsModalVisible(true);
                                        }
                                    }
                                };
                            }}
                            rowKey={(record: any) => record["id"]}
                        />
                    </Panel>
                ))}
            </Collapse>

            {addDeviceModalVisible &&
                <AddDevice
                    networkId={network.id}
                    isModalVisible={addDeviceModalVisible}
                    setIsModalVisible={setAddDeviceModalVisible}
                />
            }
            {isDeviceDetailsModalVisible && deviceForDetailModal &&
                <DeviceDetails
                    isModalVisible={isDeviceDetailsModalVisible}
                    setIsModalVisible={setIsDeviceDetailsModalVisible}
                    device={deviceForDetailModal}
                />
            }
            {isEditDeviceModalVisible && deviceForDetailModal &&
                <EditDeviceName
                    isModalVisible={isEditDeviceModalVisible}
                    setIsModalVisible={setIsEditDeviceModalVisible}
                    device={deviceForDetailModal}
                    form={form}
                />
            }
        </>
    )
}
