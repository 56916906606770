import {useEffect, useState} from "react";
import {UserBasicInfo} from "../../../../../../../models/UserBasicInfo";
import {useQuery} from "react-query";
import {NetworkService} from "../../../../../../../services/NetworkService";

export function useNetworkOwners(networkId: number) {
  const [networkOwners, setNetworkOwners] = useState<UserBasicInfo[]>([]);
  const { isSuccess, data, isError, error } = useQuery(["networkOwners", networkId], () => {
    if (networkId) {
      return NetworkService.getNetworkOwnersByNetworkId(networkId).then((res) => res.data);
    }
  }, {cacheTime: 0, refetchOnWindowFocus: false});

  useEffect(() => {
    if (isSuccess && data) {
      const networkOwners: UserBasicInfo[] = [
          data.find((owner: UserBasicInfo) => owner.type === 'Owner'),
          ...data.filter((owner: UserBasicInfo) => owner.type !== 'Owner')
      ]
      setNetworkOwners(networkOwners);
    }
  }, [isSuccess, data])

  return { networkOwners, networkOwnersIsSuccess: isSuccess, networkOwnersError: error }
}
