import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { UserService } from "../../../services/UserService";

export function useUserIdByUsernameOrEmail(usernameOrEmail?: string | null) {
  const [userId, setUserId] = useState<number | null>(null);
  const { isSuccess, data, isError, error, refetch } = useQuery(["userByUsernameOrEmail", usernameOrEmail], () => {
    if (usernameOrEmail) {
      return UserService.getUserIdByUsernameOrEmail(usernameOrEmail).then((res) => res.data);
    } else {
      setUserId(null)
    }
  }, {cacheTime: 0, refetchOnWindowFocus: false});

  useEffect(() => {
    if (isSuccess && data) {
      setUserId(data.id as number);
    }
  }, [isSuccess, data])

  return { userId, isSuccess, isError, error, refetch }
}
