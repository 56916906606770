import { Alert, Form, Modal, notification } from "antd";
import { selectChangePasswordModalOpened } from "../../../redux/selectors/userSelector";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ChangePasswordRequest } from "../../../models/ChangePasswordRequest";
import { useEffect } from "react";
import { hideChangePasswordModal } from "../../../redux/slices/userSlice";
import { ChangePasswordForm } from "./components/ChangePasswordForm";
import { useChangePassword } from "./hooks/useChangePassword";

export function ChangePassword() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const userChangePasswordModalVisible = useAppSelector(
    selectChangePasswordModalOpened
  );

  const { submitChangePassword, isLoading, isSuccess, data, error, isError } =
    useChangePassword();

  const handleChangePassword = (data: any) => {
    const userPasswordInfo = data as ChangePasswordRequest;
    submitChangePassword(userPasswordInfo);
  };

  let validationError;
  const potentialError = error as any;
  if (potentialError?.response?.status === 422) {
    validationError = potentialError?.response?.data;
  }

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(hideChangePasswordModal());
      notification.success({
        message: "You've successfully changed your password!",
      });
    }
  }, [isSuccess, dispatch, data]);

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(hideChangePasswordModal());
  };

  return (
    <Modal
      title="Change Password"
      open={userChangePasswordModalVisible}
      okButtonProps={{
        loading: isLoading,
        style: { background: '#05a955', borderColor: '#05a955' }
      }}
      bodyStyle={{
        height: 400,
        overflowY: "auto",
      }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {isError && !validationError && (error as any) && (
        <Alert
          style={{ marginBottom: 10 }}
          message={
            (error as any)?.response?.data?.detail || "An error occurred"
          }
          type="error"
        />
      )}
      {isError &&
        validationError &&
        validationError.detail.map((e: any, idx: number) => (
          <Alert
            key={idx}
            style={{ marginBottom: 10 }}
            type="error"
            message={
              <>
                <span style={{ fontWeight: "bold" }}>{e.loc[1]}:</span> {e.msg}
              </>
            }
          />
        ))}
      <ChangePasswordForm
        form={form}
        handleSubmit={handleChangePassword}
        isLoading={isLoading}
      />
    </Modal>
  );
}
