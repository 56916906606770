import { Form, Input } from "antd";
import { AyrmeshButton } from "../../../../shared-components/ayrmesh-button/AyrmeshButton";
type ChangePasswordFormProps = {
    handleSubmit: any;
    form: any;
    isLoading: boolean;
};
export function ChangePasswordForm({
    form,
    handleSubmit,
    isLoading,
}: ChangePasswordFormProps) {

    const charValidator = (rule: any, value: string, callback: any) => {
        const pattern = /[$&?<>*|\/\\'“"]/;
        if (pattern.test(value)) {
            callback('Password must not contain the following characters: $, &, ?, <, >, *, | , /, \\, \', “');
        } else {
            callback();
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={handleSubmit}
            autoComplete="off"
        >
            <Form.Item
                style={{ marginBottom: 5 }}
                label="Old Password"
                name="old_password"
                rules={[{ required: true, message: "Please input your password!" }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Confirm old password"
                name="confirm_password"
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("old_password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error("The two passwords that you entered do not match!")
                            );
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 5 }}
                label="New Password"
                name="new_password"
                rules={[
                    { required: true, message: "Please input your new password!" },
                    { validator: charValidator }
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: 10, marginTop: 20, textAlign: "center" }}
            >
                <AyrmeshButton
                    style={{
                        display: "none",
                    }}
                    loading={isLoading}
                    block
                    type="primary"
                    htmlType="submit"
                >
                    Submit
                </AyrmeshButton>
            </Form.Item>
        </Form>
    );
}
