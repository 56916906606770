import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  userEditModalOpened: boolean;
  changePasswordModalOpened: boolean;
}

const initialState: UserState = {
  userEditModalOpened: false,
  changePasswordModalOpened: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showChangePasswordModal: (state) => {
      state.changePasswordModalOpened = true;
    },
    hideChangePasswordModal: (state) => {
      state.changePasswordModalOpened = false;
    },
    showEditUserModal: (state) => {
      state.userEditModalOpened = true;
    },
    hideEditUserModal: (state) => {
      state.userEditModalOpened = false;
    },
  },
});

export const {
  showChangePasswordModal,
  hideChangePasswordModal,
  showEditUserModal,
  hideEditUserModal,
} = userSlice.actions;

export default userSlice.reducer;
