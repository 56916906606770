import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../../../../redux/selectors/authSelector";
import {TypeOfDevice} from "../../../../../enums/TypeOfDevice";
import React from "react";
import {Device} from "../../../../../models/Device";
import moment from 'moment';

export function useDeviceColumns() {
  const loggedInUser = useSelector(selectLoggedInUser);
  return [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      inputType: "text",
      align: "center",
      width: '22%',
      sorter: (a: Device, b: Device) => {
        return a.name && a.name.localeCompare(b.name)
      }
    },
    {
      title: "MAC Address",
      dataIndex: "MAC",
      editable: false,
      inputType: "text",
      align: "center",
      width: '22%',
      sorter: (a: Device, b: Device) => {
        return a.MAC && a.MAC.localeCompare(b.MAC)
      }
    },
    {
      title: "IP Address",
      dataIndex: "lan_ipaddr",
      editable: false,
      inputType: "text",
      align: "center",
      width: '22%',
      sorter: (a: Device, b: Device) => {
        return a.lan_ipaddr && a.lan_ipaddr.localeCompare(b.lan_ipaddr)
      },
    },
    {
      title: "Last Checkin",
      dataIndex: "last_checkin",
      editable: false,
      inputType: "text",
      align: "center",
      width: '22%',
      sorter: (a: Device, b: Device) => {
        return moment(a.last_checkin).unix() - moment(b.last_checkin).unix();
      },
      render: (date: string) => {
        if (!date) {
          return "No checkin yet";
        }
        const d = new Date(date);
        let datetime = d.toLocaleString();
        if (loggedInUser) {
          try {
            const moment = require('moment-timezone');
            const eventUtc = moment.utc(datetime);
            const eventTimeZone = eventUtc.tz(loggedInUser.time_zone);
            datetime = eventTimeZone.format('MM/DD/YYYY, h:mm:ss A');
            if (datetime === 'Invalid date') {
              datetime = d.toLocaleString();
            }
          } catch (e) {
            console.log("Timezone:", loggedInUser.time_zone);
            console.warn("invalid datetime format. Falling back to locale");
            console.error(e);
            datetime = d.toLocaleString();
          }
        }
        return datetime;
      },
    },
  ];
}
