import { Layout } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AppHeader } from "./layout/app-header/AppHeader";
import { useEffect } from "react";
import { setLoggedInUser } from "../../redux/slices/authSlice";
import { useUser } from "./hooks/useUser";
import { Outlet } from "react-router-dom";
import { EditUser } from "../auth/edit-user/EditUser";
import {
  selectChangePasswordModalOpened,
  selectUserEditModalOpened,
} from "../../redux/selectors/userSelector";
import { ChangePassword } from "../auth/change-password/ChangePassword";

const { Content, Footer } = Layout;

type AppProps = {
  userID: number | null;
};

export function App({ userID }: AppProps) {
  const currentYear = new Date().getFullYear();
  const dispatch = useAppDispatch();
  const userEditModalVisible = useAppSelector(selectUserEditModalOpened);
  const changePasswordModalVisible = useAppSelector(
    selectChangePasswordModalOpened
  );
  const { user } = useUser(userID);

  useEffect(() => {
    if (user) {
      dispatch(setLoggedInUser(user));
    }
  }, [user, dispatch]);

  return (
    <Layout>
      <AppHeader />
      <Content
        className="site-layout"
        style={{ padding: "0 50px", marginTop: 64 }}
      >
        <Outlet />
        {userEditModalVisible && <EditUser />}
        {changePasswordModalVisible && <ChangePassword />}
      </Content>
      <Footer style={{ textAlign: "center" }}>Ayrmesh © {currentYear}</Footer>
    </Layout>
  );
}
