import {Col, Modal, Row, Table} from "antd";
import {Device} from "../../../../../../models/Device";
import {useAppSelector} from "../../../../../../redux/hooks";
import {selectLoggedInUser} from "../../../../../../redux/selectors/authSelector";
import {DeviceDetailRow} from "./device-details-row/DeviceDetailsRow";
import {Role} from "../../../../../../enums/Role";
import {TypeOfDevice} from "../../../../../../enums/TypeOfDevice";
import moment from "moment-timezone";

type DeviceDetailsProps = {
    isModalVisible: boolean;
    setIsModalVisible: (isVisible: boolean) => void;
    device: Device;
}

export function DeviceDetails({
    isModalVisible,
    setIsModalVisible,
    device
}: DeviceDetailsProps) {

    const loggedInUser = useAppSelector(selectLoggedInUser);

    const formattedDate = (date: Date) => {
        const d = new Date(date);
        let datetime = d.toLocaleString();
        if (loggedInUser) {
            try {
                const moment = require('moment-timezone');
                const eventUtc = moment.utc(datetime);
                const eventTimeZone = eventUtc.tz(loggedInUser.time_zone);
                datetime = eventTimeZone.format('MM/DD/YYYY, h:mm:ss A');
            } catch(e) {
                console.log("Timezone:", loggedInUser.time_zone);
                console.warn("invalid datetime format. Falling back to locale");
                console.error(e);
                datetime = d.toLocaleString();
            }
        }
        return datetime;
    }

    const formatTypeColumn = (device: Device) => {
        return device.model ? (TypeOfDevice as any)[device.model] : device.type ? (TypeOfDevice as any)[device.type] : 'Unknown';
    }

    const macIPColumns = [
        {
            title: "MAC Address",
            dataIndex: "macAddress",
        },
        {
            title: 'IP Address',
            dataIndex: 'ipAddress'
        }
    ]

    const parseDHCPTableData = (dhcpData: string) => {
        const wifiClients: any[] = [];
        if (dhcpData.includes('**;')) {
            const macAddresses = dhcpData.split('**;')
            macAddresses.forEach(macAddress => {
                if (macAddress) {
                    wifiClients.push(
                        {
                            macAddress
                        }
                    )
                }
            })
        } else {
            const macAddressesWithIPAddresses = dhcpData.split('*;')
            macAddressesWithIPAddresses.forEach(macAndIP => {
                if (macAndIP) {
                    const macIP = {
                        macAddress: macAndIP.split('*')[0],
                        ipAddress: macAndIP.split('*')[1]
                    }
                    wifiClients.push(macIP);
                }
            })
        }
        return wifiClients;
    }

    return (
        <Modal
            title="Device details"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width={900}
        >
            <DeviceDetailRow labelFirst={'Name'} valueFirst={device?.name} labelSecond={'Type'} valueSecond={formatTypeColumn(device)} />
            <DeviceDetailRow labelFirst={'Channel'} valueFirst={device?.channel} labelSecond={'SSID'} valueSecond={device?.ssid} />
            <DeviceDetailRow labelFirst={'MAC Address'} valueFirst={device?.MAC} labelSecond={'IP Address'} valueSecond={device?.lan_ipaddr} />
            <DeviceDetailRow labelFirst={'Last checkin'} valueFirst={device?.last_checkin ? formattedDate(device?.last_checkin) : 'No checkin yet'} labelSecond={'Passkey'} valueSecond={device?.passkey} />
            <DeviceDetailRow labelFirst={'Role'} valueFirst={device?.role === 'R' ? Role.REMOTE : device.role === 'G' ? Role.GATEWAY : ''} labelSecond={'Uptime'} valueSecond={device?.uptime} />
            <DeviceDetailRow labelFirst={'Bandwidth'} valueFirst={device?.NTR} />

            <Row style={{marginTop: 15, marginBottom: 15}}>
                <Col span={4}>
                    <p>WiFi Clients:</p>
                </Col>
            </Row>
            {device?.dhcp_table &&
                <Row style={{width: '80%', margin: 'auto'}}>
                    <Col span={24}>
                        <Table
                            className={'devices-table'}
                            bordered
                            pagination={false}
                            dataSource={parseDHCPTableData(device?.dhcp_table)}
                            columns={macIPColumns as any} />
                    </Col>
                </Row>
            }
        </Modal>
    )
}
