import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DeviceService } from "../../../../../services/DeviceService";
import { DeviceMapping } from "../../../../../models/DeviceMapping";

export function useDeviceMappingTypes() {
  const [deviceMappings, setDevicesMappings] = useState<DeviceMapping[] | null>(null);
  const { isSuccess, data, isLoading, refetch } = useQuery(["device-mappings"], () => {
      return DeviceService.getDeviceMappings().then((res) => res.data);
  }, {cacheTime: 0, refetchOnWindowFocus: false});

  useEffect(() => {
    if (data) {
      setDevicesMappings(data as DeviceMapping[]);
    }
  }, [isSuccess, data, isLoading])
  return { deviceMappings, isSuccess, isLoading, refetch }
}
