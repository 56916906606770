import {AuthService} from "../../../../services/AuthService";
import {useMutation} from "react-query";
import {ForgotPasswordRequest} from "../../../../models/ForgotPasswordRequest";

export function useForgotPassword() {
    const mutation = useMutation((forgotPasswordRequest: ForgotPasswordRequest) =>
        AuthService.forgotPassword(forgotPasswordRequest)
    );

    return {
        submit: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
