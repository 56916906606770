import {Checkbox, Form, Input, Select} from "antd";
import { AyrmeshButton } from "../../../../shared-components/ayrmesh-button/AyrmeshButton";
import { User } from "../../../../models/User";
import countries from '../../../../assets/countries.json';
import timezones from '../../../../assets/time-zones.json';
import {useUsernameAvailability} from "../../register/hooks/useUsernameAvailability";
import {useEffect, useMemo, useState} from "react";
import debounce from "lodash/debounce";
import '../EditUser.css';

type EditUserFormProps = {
    handleSubmit: any;
    form: any;
    isLoading: boolean;
    loggedInUser: User | null;
};

export function EditUserForm({
    form,
    handleSubmit,
    isLoading,
    loggedInUser,
}: EditUserFormProps) {
    const [username, setUsername] = useState<string | null>(null);
    const {isLoading: isUsernameAvailabilityLoading, usernameAvailable} = useUsernameAvailability(username)
    const debounceSearch = useMemo(() => {
        const searchByUsername = (username: string) => {
            if (username) {
                setUsername(username);
            } else {
                setUsername(null);
            }
        }
        return debounce(searchByUsername, 300);
    }, [setUsername]);

    useEffect(() => {
        if (usernameAvailable !== null && usernameAvailable !== undefined) {
            form.validateFields(['username']);
        }
    }, [usernameAvailable])

    const timeZoneOptions = [...timezones].map((e) => {
            return {
                value: e,
                label: e
            };
        }
    );

    return (
        <div className="edit-user-form-container">
            {loggedInUser && (
                <Form
                    form={form}
                    layout="vertical"
                    name="basic"
                    onFinish={handleSubmit}
                    initialValues={loggedInUser}
                    autoComplete="off"
                >
                    <Form.Item
                        name="subscribed_to_newsletter"
                        initialValue={true}
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Subscribe to Ayrstone Newsletter
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Email"
                        name="email"
                        rules={[
                            { type: "email" },
                            { required: true, message: "Please input your email!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Username"
                        name="username"
                        rules={[
                            { required: true, message: 'Please input your username!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.length < 4) return Promise.reject("Username must be at least 4 characters long");
                                    const pattern = /[$&?<>*|\/\\'“"]/;
                                    if (pattern.test(value)) {
                                        return Promise.reject('Username must not contain the following characters: $, &, ?, <, >, *, | , /, \\, \', “');
                                    }
                                    if (usernameAvailable || value === loggedInUser?.username) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Username is already taken');
                                },
                            })
                        ]}
                    >
                        <Input onChange={(e) => debounceSearch(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="First Name"
                        name="first_name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Last Name"
                        name="last_name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Phone Number"
                        name="phone"
                        rules={[{
                            max: 12,
                            message: 'Maximum number of digits is 12'
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Business Name"
                        name="business_name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Mail Address"
                        name="mail_address"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Mail Address 2"
                        name="mail_address_2"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="City"
                        name="city"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="State"
                        name="state"
                        rules={[{
                            max: 32,
                            message: 'Maximum number of characters is 32'
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="County"
                        name="county"
                        rules={[{
                            max: 32,
                            message: 'Maximum number of characters is 32'
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Zip"
                        name="zip"
                        rules={[{
                            max: 32,
                            message: 'Maximum number of digits is 32'
                        }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Country"
                        name="country"
                        rules={[{
                            max: 32,
                            message: 'Maximum number of characters is 32'
                        }]}
                    >
                        <Select
                            showSearch={true}
                            filterOption={(input, option) => {
                                return (option?.label.toLowerCase() ?? '').includes(input);
                            }}
                            options={countries}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 5 }}
                        label="Time Zone"
                        name="time_zone"
                    >
                        <Select
                            showSearch={true}
                            options={timeZoneOptions}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 10, marginTop: 20, textAlign: "center" }}
                    >
                        <AyrmeshButton
                            style={{
                                display: "none",
                            }}
                            loading={isLoading}
                            block
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </AyrmeshButton>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
}
