import {API_BASE_URL} from "../configs/AppConfig";
import authService from "../configs/AxiosInterceptor";
import {Device} from "../models/Device";

export interface DeviceSearchOptions {
    mac_address: string | null;
    device_name: string | null;
}

export class DeviceService {
    private static RESOURCE_BASE = `${API_BASE_URL}/api/v1/devices`;

    static getDevicesForNetwork(networkId: number, searchOptions: DeviceSearchOptions | null) {
        let queryParams: any = {};
        if (searchOptions) {
            queryParams = searchOptions;
        }
        return authService.get(`${this.RESOURCE_BASE}/network/${networkId}`, {params: queryParams});
    }

    static addDeviceToNetwork(device: Device) {
        return authService.post(`${this.RESOURCE_BASE}`, device);
    }

    static updateDevice(device: Device) {
        return authService.put(`${this.RESOURCE_BASE}/${device.id}`, device);
    }

    static softDeleteDevice(device: Device) {
        return authService.put(`${this.RESOURCE_BASE}/soft-delete/${device.id}`, {});
    }

    static getDeviceMappings() {
        return authService.get(`${this.RESOURCE_BASE}/mappings`);
    }
}
