import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from './AppConfig';
import { JWT_REFRESH_TOKEN_KEY, JWT_TOKEN_KEY } from '../constants/APIConstants';
import { store } from '../redux/store';
import { login, logout } from '../redux/slices/authSlice';

const authService = axios.create({
  timeout: 60 * 1000, // 60 seconds
});

const AUTHORIZATION_HEADER_KEY = 'Authorization';
const RESOURCE_BASE = `${API_BASE_URL}/api/v2/auth`;
let refreshingToken = false;
let pendingRequests: Array<(token: string | null) => void> = [];


authService.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(JWT_TOKEN_KEY);
    if (config.headers) {
      config.headers[AUTHORIZATION_HEADER_KEY] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const response = error.response as AxiosResponse;
      const originalRequest = error.config;

      if (!refreshingToken && error.response.status === 403 && error.response.data.detail === 'Invalid or expired token' && !originalRequest._retry) {
        refreshingToken = true;
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem(JWT_REFRESH_TOKEN_KEY);
          if (!refreshToken) {
            store.dispatch(logout());
            return;
          }
          const refreshTokenObj = {
            token: refreshToken
          }
          const {data} = await axios.post(`${RESOURCE_BASE}/refresh-token`, refreshTokenObj);
          store.dispatch(login(data));
          const newToken = data.token;

          pendingRequests.forEach((callback) => callback(newToken));
          pendingRequests = [];

          originalRequest.headers["Authorization"] = `Bearer ${data.token}`;
          refreshingToken = false;
          return authService(originalRequest);
        } catch (refreshError) {
          console.error("Refresh token expired", refreshError);
          store.dispatch(logout());

          pendingRequests.forEach((callback) => callback(null));
          pendingRequests = [];

          refreshingToken = false;
        }
      } else if (refreshingToken) {
          return new Promise((resolve, reject) => {
            pendingRequests.push((newToken) => {
              if (newToken) {
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                resolve(authService(originalRequest));
              } else {
                reject(error);
              }
            });
          });
      }

      if (response.status === 403) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default authService;
