import {NetworkTable} from "./network-table/NetworkTable";

export function Network() {

    return (
        <div style={{marginTop: 10}}>
            <NetworkTable />
        </div>
    )
}
