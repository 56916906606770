import {useMutation, useQueryClient} from "react-query";
import {NetworkService} from "../../../../../../../services/NetworkService";
import {Network} from "../../../../../../../models/Network";

export function useEditNetwork() {
    const queryClient = useQueryClient();
    const mutation = useMutation((network: Network) =>
        NetworkService.updateNetwork(network).then(res => res.data), {
        onSuccess: (data, variables) => {
            const previousNetworks: Network[] | undefined = queryClient.getQueryData(
                ["networks"]
            );
            if (!previousNetworks) return;
            let updatedNetworks: Network[] = previousNetworks.slice();
            const networkToUpdate = updatedNetworks.find(n => n.id === data.id);
            if (!networkToUpdate) return;
            const idxToUpdate = updatedNetworks.indexOf(networkToUpdate);

            updatedNetworks = [
                ...updatedNetworks.slice(0, idxToUpdate),
                variables,
                ...updatedNetworks.slice(idxToUpdate + 1),
            ];
            queryClient.setQueryData(["networks"], updatedNetworks);
        }
    });

    return {
        submitEditNetwork: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccessEditNetwork: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
