import {Button, Spin} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {LOGIN} from "../../../constants/RoutePathConstants";
import logo from "../../../assets/logo.png";
import {useEffect} from "react";
import {useConfirmEmail} from "./hooks/useConfirmEmail";
import {ConfirmEmailRequest} from "../../../models/ConfirmEmailRequest";


export function ConfirmEmail() {
    const { submit, isSuccess, isError, isLoading } = useConfirmEmail();
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.token) {
            const confirmEmailRequest: ConfirmEmailRequest = {
                confirm_email_hash: params.token
            }
            submit(confirmEmailRequest);
        }
    }, [])

    const navigateToLogin = () => {
        navigate(LOGIN);
    }

    const renderConfirmEmailResponse = () => {
        if (isLoading) {
            return <Spin spinning={isLoading} />
        } else if (isError) {
            return <h3 style={{marginTop: 10, marginBottom: 10}}>Error happened during confirmation of your email address.</h3>
        } else if (isSuccess) {
            return <h3 style={{marginTop: 10, marginBottom: 10}}>We have successfully confirmed your email address.</h3>
        }

    }

    return (
        <div className="container">
            <img width="300px" src={logo} alt="Ayrstone logo" />
            {renderConfirmEmailResponse()}
            <div style={{textAlign: 'center'}}>
                <Button onClick={() => navigateToLogin()} style={{paddingLeft: 0}} type="link">Sign in</Button>
            </div>
        </div>
    )
}
