import {useMutation} from "react-query";
import {useEffect, useState} from "react";
import {UserService} from "../../../../services/UserService";
import {EditUserRequest} from "../../../../models/EditUserRequest";


export function useEditUser() {
    const mutation = useMutation((editUser: EditUserRequest) =>
        UserService.editUser(editUser)
    );
    const [res, setRes] = useState(null);
    useEffect(() => {
        if (mutation.isSuccess && mutation.data) {
            setRes(mutation.data.data);
        }
    }, [mutation.isSuccess, mutation.data])

    return {
        submitEditUser: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        data: res,
        error: mutation.error,
        isError: mutation.isError,
    }
}
