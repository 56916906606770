import {useLocation} from "react-router-dom";
import {Alert} from "antd";

export function AfterRegister() {
  const location = useLocation();
  return (
    <>
      {location.state && location.state.justRegistered && (
          <Alert style={{width:300}} message={"You've successfully registered. Login with your new account."} type="success" />
      )}
    </>
  );
}