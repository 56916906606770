import {useMutation, useQueryClient} from "react-query";
import {DeviceService} from "../../../../../../../services/DeviceService";
import {Device} from "../../../../../../../models/Device";


export function useAddDevice() {
    const queryClient = useQueryClient();
    const mutation = useMutation((device: Device) =>
        DeviceService.addDeviceToNetwork(device).then(res => res.data), {
            onSuccess: (data) => {
                let previousDevices: Device[] | undefined = queryClient.getQueryData(
                    ["devices", { network_id: data.network_id }]
                );
                let allDevices;
                if (previousDevices) {
                    allDevices = [
                        ...previousDevices.slice(),
                        data,
                    ];
                } else {
                    allDevices = [data]
                }

                queryClient.setQueryData(["devices", { network_id: data.network_id }], allDevices);
            }
        }
    );

    return {
        submitAddDevice: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
