import axios from "axios";
import { API_BASE_URL } from "../configs/AppConfig";
import { RegisterRequest } from "../models/RegisterRequest";
import { ForgotPasswordRequest } from "../models/ForgotPasswordRequest";
import { ResetPasswordRequest } from "../models/ResetPasswordRequest";
import { ConfirmEmailRequest } from "../models/ConfirmEmailRequest";
import { ChangePasswordRequest } from "../models/ChangePasswordRequest";
import authService from "../configs/AxiosInterceptor";
import {ResendConfirmationEmailRequest} from "../models/ResendConfirmationEmailRequest";

export class AuthService {
    private static RESOURCE_BASE = `${API_BASE_URL}/api/v1/auth`;
    private static RESOURCE_BASE_V2 = `${API_BASE_URL}/api/v2/auth`;

    static login(username: string, password: string) {
        return axios.post(`${this.RESOURCE_BASE}/login`, {
            username,
            password,
        });
    }

    static secureLogin(username: string, password: string) {
        return axios.post(`${this.RESOURCE_BASE_V2}/login`, {
            username,
            password,
        });
    }

    static checkUsernameAvailability(username: string) {
        return axios.get(`${this.RESOURCE_BASE}/username-availability/${username}`);
    }

    static register(registerInfo: RegisterRequest) {
        return axios.post(`${this.RESOURCE_BASE}/register`, registerInfo);
    }

    static forgotPassword(forgotPasswordRequest: ForgotPasswordRequest) {
        return axios.post(
            `${this.RESOURCE_BASE}/forgot-password`,
            forgotPasswordRequest
        );
    }

    static forgotUsername(email: string) {
        return axios.get(
            `${this.RESOURCE_BASE}/forgot-username/${email}`,
        );
    }

    static resetPassword(resetPasswordRequest: ResetPasswordRequest) {
        return axios.post(
            `${this.RESOURCE_BASE}/reset-password`,
            resetPasswordRequest
        );
    }

    static confirmEmail(confirmEmailRequest: ConfirmEmailRequest) {
        return axios.post(
            `${this.RESOURCE_BASE}/confirm-email`,
            confirmEmailRequest
        );
    }

    static changePassword(changePasswordRequest: ChangePasswordRequest) {
        return authService.post(
            `${this.RESOURCE_BASE}/change-password`,
            changePasswordRequest
        );
    }

    static resendConfirmationEmail(resendConfirmationEmailRequest: ResendConfirmationEmailRequest) {
        return axios.post(
            `${this.RESOURCE_BASE}/resend-confirmation-email`,
            resendConfirmationEmailRequest
        );
    }

}
