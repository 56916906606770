import {AuthService} from "../../../../services/AuthService";
import {useMutation} from "react-query";
import {ResetPasswordRequest} from "../../../../models/ResetPasswordRequest";

export function useResetPassword() {
    const mutation = useMutation((resetPasswordRequest: ResetPasswordRequest) =>
        AuthService.resetPassword(resetPasswordRequest)
    );

    return {
        submit: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
