import React, {useEffect} from 'react';
import './App.css';
import {Login} from "./pages/auth/login/Login";
import {Routes, Route, useNavigate, useLocation, Navigate} from 'react-router-dom';
import {
  APP,
  CONFIRM_EMAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_TOKEN, FORGOT_USERNAME,
  LOGIN,
  REGISTER
} from "./constants/RoutePathConstants";
import {useAppSelector} from "./redux/hooks";
import {selectUserID} from "./redux/selectors/authSelector";
import { App as AppDashboard } from './pages/app/App';
import {Register} from "./pages/auth/register/Register";
import {Home} from "./pages/app/layout/home/Home";
import {ForgotPassword} from "./pages/auth/forgot-password/ForgotPassword";
import {ForgotPasswordToken} from "./pages/auth/forgot-password/ForgotPasswordToken";
import {ConfirmEmail} from "./pages/auth/confim-email/ConfirmEmail";
import {ForgotUsername} from "./pages/auth/forgot-username/ForgotUsername";

function App() {
  const navigate = useNavigate();
  const userID = useAppSelector(selectUserID);
  const location = useLocation();
  useEffect(() => {
    if (userID) {
      if (!location.pathname.includes("app")) {
        navigate(APP);
      }
    } else {
      // something has to be done here
    }
  }, [userID, location.pathname])

  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={FORGOT_USERNAME} element={<ForgotUsername />} />
      <Route path={FORGOT_PASSWORD_TOKEN} element={<ForgotPasswordToken />} />
      <Route path={CONFIRM_EMAIL} element={<ConfirmEmail />} />
        {userID &&
            <Route path={APP} element={<AppDashboard userID={userID} />} >
              <Route index element={<Home />} />
            </Route>
        }
      <Route
          path="*"
          element={<Navigate to={LOGIN} />}
      />
    </Routes>
  );
}

export default App;
