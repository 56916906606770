import {AuthService} from "../../../../services/AuthService";
import {useMutation} from "react-query";
import {ConfirmEmailRequest} from "../../../../models/ConfirmEmailRequest";

export function useConfirmEmail() {
    const mutation = useMutation((confirmEmailRequest: ConfirmEmailRequest) =>
        AuthService.confirmEmail(confirmEmailRequest)
    );

    return {
        submit: mutation.mutate,
        isLoading: mutation.isLoading,
        isSuccess: mutation.isSuccess,
        error: mutation.error,
        isError: mutation.isError,
    }
}
