import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../../../../../constants/RoutePathConstants";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { logout } from "../../../../../../redux/slices/authSlice";
import { AyrmeshAvatar } from "../../../../../../shared-components/ayrmesh-avatar/AyrmeshAvatar";
import {
    showChangePasswordModal,
    showEditUserModal,
} from "../../../../../../redux/slices/userSlice";

type ProfileDropdownProps = {
    firstName: string;
    lastName: string;
    username: string;
};

export function ProfileDropdown({
    firstName,
    lastName,
    username,
}: ProfileDropdownProps) {
    let initials = "";
    if (firstName && lastName) {
        initials = firstName[0] + lastName[0];
    } else if (username) {
        initials = username[0];
    }

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
        navigate(LOGIN);
    };
    const openUserEditModal = () => {
        dispatch(showEditUserModal());
    };
    const openChangePasswordModal = () => {
        dispatch(showChangePasswordModal());
    };

    const items: MenuProps["items"] = [
        {
            label: <span style={{ fontWeight: "bold" }}>{username}</span>,
            key: "username",
        },
        {
            type: "divider",
        },
        {
            label: "Edit profile",
            onClick: openUserEditModal,
            key: "editUser",
        },
        {
            label: "Change password",
            onClick: openChangePasswordModal,
            key: "changePassword",
        },
        {
            label: "Logout",
            onClick: handleLogout,
            key: "logout",
        },
    ];

    return (
        <Dropdown
            overlayStyle={{ width: 150 }}
            menu={{ items }}
            trigger={["click"]}
        >
            <a onClick={(e) => e.preventDefault()}>
                <AyrmeshAvatar>{initials}</AyrmeshAvatar>
                <DownOutlined />
            </a>
        </Dropdown>
    );
}
