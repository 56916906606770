import { useMutation } from "react-query";
import { AuthService } from "../../../../../../services/AuthService";
import { ResendConfirmationEmailRequest } from "../../../../../../models/ResendConfirmationEmailRequest";


export function useResendVerificationEmail() {
  const mutation = useMutation((resendConfirmationEmailRequest: ResendConfirmationEmailRequest) =>
      AuthService.resendConfirmationEmail(resendConfirmationEmailRequest).then(res => res.data), {
    onSuccess: (data, variables) => {
    }
  });

  return {
    submitResendVerificationEmail: mutation.mutate,
    isLoading: mutation.isLoading,
    resendVerificationEmailIsSuccess: mutation.isSuccess,
    resendVerificationEmailError: mutation.error,
    isError: mutation.isError
  }
}
