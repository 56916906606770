export enum TypeOfDevice {
    "BRIDGE-A" = 'Bridge radio',
    "BRIDGE-AQ" = 'Bridge radio',
    "HUB2N" = 'Hub2n',
    "HUB2T" = 'Hub2T',
    "HUB2T16M" = 'Hub2T',
    "HUB2T8M" = 'Hub2T',
    "HUB2X2" = 'Hub2x2',
    "HUB2X2Q" = 'Hub2x2',
    "HUB2X2LC" = 'Hub2x2C',
    "INDOORAP" = 'IndoorAP',
    "INDOORHUB" = 'IndoorHub',
    "INDOORHUB-C" = 'IndoorHub',
    "RECEIVER" = 'Receiver',
    "RECEIVERC" = 'ReceiverC',
    "HUBDUO1" = "Hubduo",
    "HUBDUO-EXTRA" = "Hubduo",
    "hub2" = 'Hub2',
    "bridge" = 'Bridge radio',
    "receiver" = 'Receiver',
    "receiverld" = 'IndoorHub'
}
