import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { User } from "../../../models/User";
import { UserService } from "../../../services/UserService";

export function useUser(userID: number | null) {
    const [user, setUser] = useState<User | null>(null);
    const { isSuccess, data } = useQuery(["user", userID], () => {
        if (userID) {
            return UserService.getUserByID(userID).then((res) => res.data);
        }
    }, {cacheTime: 0, refetchOnWindowFocus: false});

    useEffect(() => {
        if (isSuccess && data) {
            setUser(data as User);
        }
    }, [isSuccess, data])
    return { user, isSuccess }
}
